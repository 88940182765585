import React, { useState, useMemo, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import { SelectSucursal } from '../../../components/Selects/SelectsPrincipales'
import { AButton } from '../../../components/Button';
import {  notify_valido, notify_warning, notify_error, newDateFValor } from '../../../components/utils';
import { Tooltip, Box, Modal, createTheme, ThemeProvider } from '@mui/material';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import * as Apis from './funApis'
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id');
const globalTheme = window.templateCustomizer.settings.style;

const DAObjetivos = () => {

  const [lst, setlst] = useState([]);
  
  const [pending, setpending] = useState(false);
  const [disabledb1, setdisabledb1] = useState(false);
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());


  const [sl_t_suc, setSl_t_suc] = useState('');

  const changeSl_t_suc = (event) => { 
    setSl_t_suc(event.target.value);
  }


  const onClickListar = async () => {
    try {
      if (!sl_t_suc) {
        notify_warning('Primero debe seleccionar una sucursal');
        return;
      }

      const body = {
        vcodEmpresa: cook_idEmp,
        vcodSucursal: sl_t_suc,
        vPeriodo: inp_text_fecha.toISOString().slice(0, 7)
      }

      setpending(true)
      setdisabledb1(true)
      const data = await Apis.apiListaObjetivoDavinci(body);
      setlst(data)
      setdisabledb1(false)
      setpending(false)

    } catch (error) {
      setdisabledb1(false)
      setpending(false)
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }

  const onClickLimpiar = async () => {
    try {
      Swal.fire({
        title: '¿Está seguro?',
        text: '¿Desea limpiar los filtros y la tabla de datos?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, limpiar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          setlst([]);
          setSl_t_suc('');
          setInp_text_fecha(new Date());
          notify_valido('Se limpiaron los datos correctamente');
        }
      });
    } catch (error) {
      notify_error('Error, intente mas tarde o comuniquese con area de GTI.');
    }
  }

  const columns = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Periodo', accessorKey: 'Periodo' },
    { header: 'CodCliente', accessorKey: 'CodCliente' },
    { header: 'CodSucursal', accessorKey: 'CodSucursal' },
    { header: 'ObjSoles', accessorKey: 'ObjSoles' },
    { header: 'ObjSurtidoMe', accessorKey: 'ObjSurtidoMe' },
    { header: 'ObjSurtidoV1', accessorKey: 'ObjSurtidoV1' },
    { header: 'ObjSurtidoV2', accessorKey: 'ObjSurtidoV2' }
  ], []);



  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme,
          background: {
            default:
              globalTheme === 'light'
                ? 'rgb(254,255,244)'
                : '#2B2C40',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
      }),
    [globalTheme],
  );

  const fc_cargar_excel = async (event) => {
    const body = {
      vcodEmpresa: cook_idEmp,
      vcodSucursal: sl_t_suc,
      vPeriodo: inp_text_fecha.toISOString().slice(0, 7)
    };
    Swal.fire({
      title: 'Procesando...',
      html: 'Por favor esperar un momento, gracias.',
      didOpen: () => { Swal.showLoading() },
      customClass: { container: 'my-swal' },
      allowOutsideClick: false
    });

    try {

      const respuesta = await Apis.apiVerificarArchivoExistente(body);

      if (respuesta.existe) {
        Swal.fire({
          icon: 'warning',
          title: 'Archivo ya cargado',
          text: 'Ya existe un archivo cargado . No se puede subir otro archivo.',
          confirmButtonText: 'Aceptar',
        });
        return;
      }

      const file = event.target.files[0];

      const reader = new FileReader();
      
      reader.onload = async (e) => {

        const data = new Uint8Array(e.target.result);

        const jsonDataRes = fc_extructura_data_excel(data);
        
        const rspEnvio = await fc_envio_datos(jsonDataRes);


        if (rspEnvio.vlResp.insert) {

          setlst([]);
          notify_valido("Se procesó correctamente");
          Swal.close();
        }
      };

      reader.onerror = (err) => {
        console.error('Error al leer el archivo:', err);
        Swal.close();
      };
      
      reader.readAsArrayBuffer(file);
      event.target.value = null;

    } catch (error) {
      console.error("Error al verificar archivo existente:", error);
      notify_error('Error al verificar si existe archivo cargado. Intente más tarde.');
      Swal.close();
    }
  };

  const fc_extructura_data_excel = (data) =>{
    const workbook = XLSX.read(data, { type: 'array' });
    const sheet = workbook.Sheets['clientes DB'];
    if (!workbook.Sheets['clientes DB']) {
      Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'La hoja "clientes DB" no existe en el archivo.',
      });
      return;
  }
    const ref = sheet['!ref'];
    const range = XLSX.utils.decode_range(ref);
    const headerColumns = [];
    for (let col = range.s.c + 0; col <= 6; col++) {
      const columnName = XLSX.utils.encode_col(col);
      const headerCell = sheet[XLSX.utils.encode_cell({ r: 2, c: col })];
      headerColumns.push(headerCell ? headerCell.v : columnName); 
    }
    const dataRange = `A4:${XLSX.utils.encode_col(range.e.c)}${range.e.r + 1}`;
    let jsonData = XLSX.utils.sheet_to_json(sheet, {
      range: dataRange,
      header: headerColumns,
      cellDates: true, // Para manejar fechas correctamente
      raw: false, // Para que aplique la función de limpiezas
      cellFormula: false, // Para que no se interpreten fórmulas
      cellHTML: false, // Para que no se interpreten HTML en las celdas
      cellText: false, // Para que no se interpreten datos de texto enriquecido
      cellNF: false, // Para que no se interpreten formatos de números
      cellStyles: false, // Para que no se interpreten estilos de celdas
      //cellDates: true, // Para manejar fechas correctamente
      defval: '' // Valor por defecto para celdas vacías
    });
    return jsonData;
  }


  const fc_envio_datos = async (arrData) => {
    const body = {
      vcodEmpresa : cook_idEmp,
      vlfecha : inp_text_fecha.toISOString().slice(0, 7),
      vArrDatosEn : arrData 
    }
    const data = await Apis.apiSubirArchivoDatosExcelObjetivosDavinci(body)
    return data;
  }


  const [openModal, setOpenModal] = useState(false);
  //const [codExtrModalUni, setCodExtrModalUni] = useState('');
  const hanOpenModal = async(row) => {
  }



  return (
    <div className="col-xl-12">
      <h6 className="text-muted ">Listar Objetivos</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row">
              <div className="col-lg-2">Sucursal:</div>
              <div className="col-lg-2">
                <SelectSucursal id="veh_slc_suc" value={sl_t_suc} onChange={changeSl_t_suc} />
              </div>
              <div className="col-lg-2">Periodo:</div>
              <div className="col-lg-2" style={{ zIndex: '3' }}>
                <DatePicker
                  id="fecha"
                  locale="es"
                  className="form-control form-control-sm text-center"
                  selected={inp_text_fecha}
                  onChange={date => setInp_text_fecha(date)}
                  dateFormat="yyyy-MM"  // Muestra solo el mes y el año
                  showMonthYearPicker  // Permite seleccionar mes y año directamente
                  autoComplete='off'
                />
              </div>
              <div className="col-lg-4 text-end ">
                <AButton tp_tilde="Consultar información" tp_posit="top" bt_class="btn btn-label-youtube btn-sm" bt_funct={onClickListar} disabled={disabledb1} ic_class="bx bx-cloud-download bx-ms" vl_tpbtn={2} />&nbsp;
                <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
                &nbsp;
              <label htmlFor="formFile" className="btn btn-sm btn-success">
                <i className='bx bx-cloud-upload bx-ms' id="btn_subirExcel_icon"></i> Migrar Formato
              </label>
              <input type="file" id="formFile" name="formFile" accept='.csv,.xlsx,.xlsm' className="form-control form-control-sm d-none" onChange={fc_cargar_excel} />
              </div>
          </div>
          <ThemeProvider theme={tableTheme}>
            <div className="card-datatable table-responsive">
              <MaterialReactTable
                columns={columns}
                data={lst}
                state={{ isLoading: pending }}
                initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
                enableGrouping
                //enableRowSelection
                //getRowId={(row) => row.Code}
                positionToolbarAlertBanner="bottom"
                //onRowSelectionChange={setRowSelection}
                enableStickyHeader enableColumnResizing
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                localization={MRT_Localization_ES}
                enablePagination={true}
                muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                enableGlobalFilterModes
                rowVirtualizerProps={{ overscan: 150 }}
                enableRowVirtualization

                // displayColumnDefOptions={{
                //   'mrt-row-actions': {
                //     header: 'Options', //change header text
                //     size: 110, //make actions column wider
                //   },
                // }}
                // enableRowActions
                // renderRowActions={({ row }) => (
                //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                //     <Tooltip title="Visualizar" placement="left">
                     
                //      <AButton tp_tilde="Cambiar Obsequio" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-warning" bt_funct={ (e) => {hanOpenModal(row.original)}} ic_class="bx bx-duplicate bx-ms" vl_tpbtn={1} />
                    
                //     </Tooltip>
                  
                    
                //   </Box>
                // )}

               
                muiCircularProgressProps={{ color: 'error' }}
                // muiSkeletonProps={{
                //   animation: 'pulse',
                //   height: 28,
                // }}

              />
            </div>
            
          </ThemeProvider>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default DAObjetivos