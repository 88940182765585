import React, {useState,useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import DESUnoAUno from './DESUnoAUno'
import DESTicketCompra from './DESTicketCompra'
import DESTicketCompraEscala from './DESTicketCompraEscala'
import DESComboEscala from './DESComboEscala'
//mport DESAgrupacionEscala from './DESAgrupacionEscala'
//import DESReglas from './DESReglas'
import DESAgrupacionRegla from './DESAgrupacionRegla'
import DESUnoAUnoDavinci from './DESUnoAUnoDavinci'


const DESIndex = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');
  const tip = searchParams.get('tip');

  const [listTabs, setListTabs] = useState({ id1: '',id2: '',id3: '',id4: '',id5: '',id6: '', id7: '' })
  const [valiTabs, setValiTabs] = useState({ id1: true,id2: true,id3: true,id4: true,id5: true,id6: true, id7: true })
  useEffect(()=>{
    setListTabs({ 
      // id1: (tab === "des1a1" || tab === null) ? 'entra' : '',//este es el valido
      id1: (tab === "des1a1" || ( tip!=='ver' && tab === null)) ? 'entra' : '',//este es el valido
      //id1: (tab === "des1a1") ? 'entra' : '',
      id2: (tab === "desticketcompra") ? "entra" : '',
      id3: (tab === "desticketcompraescala") ? "entra" : '',
      id4: (tab === "descomboescala") ? "entra" : '',
      id5: (tab === "desagrupacionescala") ? "entra" : '',
      id6: (tab === "desagrupacionreglas") ? "entra" : '' ,
      id7: (tab === "des1a1davinci") ? "entra" : '' 
    })
    setValiTabs({

      // id1: (tab === "des1a1" || tab === null) ? false : true,
      id1: (tab === "des1a1" || ( tip!=='ver' && tab === null)) ? false : true,
      id2: (tab === "desticketcompra"|| tab === null) ? false : true,
      id3: (tab === "desticketcompraescala"|| tab === null) ? false : true,
      id4: (tab === "descomboescala"|| tab === null) ? false : true,
      id5: (tab === "desagrupacionescala"|| tab === null) ? false : true,
      id6: (tab === "desagrupacionreglas"|| tab === null) ? false : true,
      id7: (tab === "des1a1davinci" || ( tip!=='ver' && tab === null)) ? false : true,

    })
  },[tab])

  const [listTabsVisu, setListTabsVisu] = useState({ id1: false,id2: false,id3: false,id4: false,id5: false,id6: false,id7: false })
  const pruebaes = (valor) => {
    //console.log("valor", valor);
    setListTabsVisu({ 
      id1: (valor === '1') ? true : false,
      id2: (valor === '2') ? true : false,
      id3: (valor === '3') ? true : false,
      id4: (valor === '4') ? true : false,
      id5: (valor === '5') ? true : false,
      id6: (valor === '6') ? true : false,
      id7: (valor === '7') ? true : false
    })
    //alert("funciona")
  }

  return (
    <div className="col-xl-12">
        <h6 className="text-muted d-none">Inicio Descuento</h6>
        <div className="nav-align-top">
            <ul className="nav nav-pills mb-3 " role="tablist">
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id1!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DES1a1" aria-controls="DES1a1" aria-selected="true" onClick={() => {pruebaes('1')}} disabled={valiTabs.id1}><i className="tf-icons bx bx-street-view bx-xs" /> Desc. 1 a 1 </button>
                </li>
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id2!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DESticketcompra" aria-controls="DESticketcompra" aria-selected="false" onClick={() => {pruebaes('2')}} disabled={valiTabs.id2}><i className="tf-icons bx bx-sticker bx-xs" /> Desc. Ticket Compra</button>
                </li>
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id3!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DESticketcompraescala" aria-controls="DESticketcompraescala" aria-selected="false" onClick={() => {pruebaes('3')}} disabled={valiTabs.id3} ><i className="tf-icons bx bxs-sticker bx-xs" /> Desc. Ticket Compra Escala</button>
                </li>
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id4!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DESComboEscala" aria-controls="DESComboEscala" aria-selected="false" onClick={() => {pruebaes('4')}} disabled={valiTabs.id4}><i className="tf-icons bx bx-popsicle bx-xs" /> Desc. Combo Escala</button>
                </li>
                {/* AGRUPACION ESCALA */}
                {/* <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id5!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DESagrupacionescala" aria-controls="DESagrupacionescala" aria-selected="false" onClick={() => {pruebaes('5')}} ><i className="tf-icons bx bx-copy-alt bx-xs" /> Desc. Agrupacion Escala</button>
                </li> */}
                {/* <li className="nav-item me-1">
                    <button type="button" className="btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4" role="tab" data-bs-toggle="tab" data-bs-target="#DESreglas" aria-controls="DESreglas" aria-selected="false"><i className="tf-icons bx bx-grid bx-xs" /> Desc. Reglas</button>
                </li> */}
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id6!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DESagrupacionreglas" aria-controls="DESagrupacionreglas" aria-selected="false" onClick={() => {pruebaes('6')}} disabled={valiTabs.id6}><i className="tf-icons bx bx-grid bx-xs" /> Desc. Agrupacion Reglas</button>
                </li>
                <li className="nav-item me-1">
                    <button type="button" className={`btn btn-sm btn-icon rounded-pill btn-danger nav-link me-4 ${listTabs.id6!=='' && 'active'}`} role="tab" data-bs-toggle="tab" data-bs-target="#DES1a1Davinci" aria-controls="DES1a1Davinci" aria-selected="false" onClick={() => {pruebaes('7')}} disabled={valiTabs.id7}><i className="tf-icons bx bx-grid bx-xs" /> Desc.Davinci</button>
                </li>
                
            </ul>
            <div className="tab-content">

              <div className={`tab-pane fade ${listTabs.id1 !== '' && 'show active'}`} id="DES1a1" role="tabpanel">
                {
                  (listTabsVisu.id1 || listTabs.id1) && (
                    <DESUnoAUno />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id2 !== '' && 'show active'}`} id="DESticketcompra" role="tabpanel">
                {
                  (listTabsVisu.id2 || listTabs.id2) && (
                    <DESTicketCompra />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id3 !== '' && 'show active'}`} id="DESticketcompraescala" role="tabpanel">
                {
                  (listTabsVisu.id3 || listTabs.id3) && (
                    <DESTicketCompraEscala />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id4 !== '' && 'show active'}`} id="DESComboEscala" role="tabpanel">
                {
                  (listTabsVisu.id4 || listTabs.id4) && (
                    <DESComboEscala />
                  )
                }
              </div>

              {/* DESAgrupacionEscala */}
              {/* <div className={`tab-pane fade ${listTabs.id5 !== '' && 'show active'}`} id="DESagrupacionescala" role="tabpanel">
                {
                  (listTabsVisu.id5 || listTabs.id5) && (
                    <DESAgrupacionEscala />
                  )
                }
              </div> */}
              {/* <div className="tab-pane fade" id="DESreglas" role="tabpanel">
                      <DESReglas />
                    </div> */}
              <div className={`tab-pane fade ${listTabs.id6 !== '' && 'show active'}`} id="DESagrupacionreglas" role="tabpanel">
                {
                  (listTabsVisu.id6 || listTabs.id6) && (
                    <DESAgrupacionRegla />
                  )
                }
              </div>
              <div className={`tab-pane fade ${listTabs.id7 !== '' && 'show active'}`} id="DES1a1Davinci" role="tabpanel">
                {
                  (listTabsVisu.id7 || listTabs.id7) && (
                    <DESUnoAUnoDavinci />
                  )
                }
              </div>
            </div>
        </div>
        <ToastContainer />
      </div>
  )
}

export default DESIndex