export  const apiListaObjetivoDavinci = async(params) => {
  const queryString = new URLSearchParams(params).toString();
  const url ='/apiListaObjetivoDavinci?'+queryString; 
  const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export  const apiSubirArchivoDatosExcelObjetivosDavinci = async(body) => {
  const url ='/apiSubirArchivoDatosExcelObjetivosDavinci'; 
  const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
  const response = await fetch(url,opPe);
  const data = await response.json();
  return data;
}
export const apiVerificarArchivoExistente = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = '/apiVerificarArchivoExistente?' + queryString;
  const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} };
  const response = await fetch(url, opPe);
  const data = await response.json();
  return data;
};

