/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal, SelectTipo } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows, notify_error } from '../../components/utils';
import $ from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const moment = require('moment')
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const globalTheme = window.templateCustomizer.settings.style;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

const styles = {

    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? null : null,
        backgroundColor: globalTheme === 'light'
            ? '#FEFFF4'
            : '#2B2C40',
        color: globalTheme === 'light'
            ? '#2B2C40'
            : '#FEFFF4',
        minHeight: `29px`,
        height: `29px`,
    }),

    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
            : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
        color: state.isSelected
            ? '#FF3E1D'
            : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
        '&:hover': {
            backgroundColor: '#FF3E1D',
            color: 'white',
        },
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
        color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
        padding: `0 6px`,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
    input: (provided, state) => ({
        ...provided,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
        fontSize: `0.75rem`,
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: `29px`,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    }),
};


export const Report1 = (prosp) => {
    const { dataTipo } = prosp

    const cookies = new Cookies();
    const cook_idEmp = cookies.get('dtEmp_id');

    const [dataReport, setdataReport] = useState([]);
    const [pending, setPending] = useState(false);
    const funDArticulos = useCallback(async (inputValue) => {
        const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(url, opPe);
        const data = await response.json();
        return data;
    }, [])

    /** Primer reporte */
    const columns = useMemo(() => [
        { header: 'TipoConsolidado', accessorKey: 'TipoConsolidado' },
        { header: 'Sucursal', accessorKey: 'Sucursal' },
        { header: 'Almacen', accessorKey: 'Almacen' },
        { header: 'FechaEmision', accessorKey: 'FechaEmision' },
        { header: 'Linea', accessorKey: 'Linea' },
        { header: 'Codigo', accessorKey: 'Codigo' },
        { header: 'Descripcion', accessorKey: 'Descripcion' },
        { header: 'UnidadVenta', accessorKey: 'UnidadVenta' },
        { header: 'FactorUndVenta', accessorKey: 'FactorUndVenta' },
        { header: 'CantidadUndVenta', accessorKey: 'CantidadUndVenta' },
        { header: 'TipoDocumento', accessorKey: 'TipoDocumento' },
        { header: 'SerieCorrelativo', accessorKey: 'SerieCorrelativo' },
        { header: 'CodigoCliente', accessorKey: 'CodigoCliente' },
        { header: 'NombreCliente', accessorKey: 'NombreCliente' },
        { header: 'Vendedor', accessorKey: 'Vendedor' },
        { header: 'Ruta', accessorKey: 'Ruta' },
        { header: 'Comentario', accessorKey: 'Comentario' },
    ], []);

    const onClickListar = async () => {
        // El Numero del reporte es el 2 que lista Documentos pendientes por despacho

        let tipoReporte = 2;
        try {
            setdataReport([])
            setPending(true)
            const url_reporte = `/apiReportAuditoria?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}`;
            console.log(url_reporte);

            const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await fetch(url_reporte, opPe);
            if (response.status != 200) {
                // Si el código de estado es 404 (no se encontraron clientes)
                notify_warning('Ocurrio un error en el proceso')
                return;
            }
            const data = await response.json();
            setdataReport(data)

        } catch (error) {
            console.log("Ocurrio un error:: ", error);
            notify_error('Ocurrio un error')

        } finally {
            setPending(false)
        }
    };
    const onClickLimpiar = async () => {
        setdataReport([])
        setPending(false)
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "DOCUMENTOS PENDIENTES POR DESPACHO_" + moment(new Date()).format('YYYYMMDD HH:mm')
        const showData = async () => {
            if (dataReport.length !== 0) {
                const ws = XLSX.utils.json_to_sheet(dataReport);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, `${nameFileType}.xlsx`);
            } else {
                notify_warning("No existen datos, verificar campos")
            }
        }
        showData();
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-10">
                </div>
                <div className="col-lg-2 text-end">

                    <Tooltip title="Consultar información" placement="top">
                        <>
                            <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </>
                    </Tooltip>
                    <Tooltip title="Exportar" placement="top">
                        <div className="btn-group" role="group">
                            <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                                {/* <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button> */}
                                <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                            </ul>
                        </div>
                    </Tooltip>
                    <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
                </div>
            </div>

            <div className="card-datatable table-responsive ">
                <MaterialReactTable
                    columns={columns}
                    data={dataReport}
                    state={{ isLoading: pending }}
                    initialState={{
                        density: 'compact', showColumnFilters: false,
                        showGlobalFilter: false,
                        columnVisibility: { clave: false, vlSema: false, },
                    }}
                    enableGrouping
                    enableStickyHeader enableColumnResizing
                    muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                    localization={MRT_Localization_ES}
                    enablePagination={true}
                    muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                    enableGlobalFilterModes
                    rowVirtualizerProps={{ overscan: 150 }}
                    enableRowVirtualization
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Options', //change header text
                            size: 120, //make actions column wider
                        },
                    }}
                    muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                        animation: 'pulse',
                        height: 28,
                    }}

                />
            </div>
        </>
    )
}

export const Report2 = (prosp) => {
    const { dataTipo } = prosp
    const [text_fechaD,    setText_fechaD] = useState(new Date());
    const [text_fechaH,    setText_fechaH] = useState(new Date());
    
    const cookies = new Cookies();
    const cook_idEmp = cookies.get('dtEmp_id');

    const [dataReport, setdataReport] = useState([]);
    const [pending, setPending] = useState(false);
    const funDArticulos = useCallback(async (inputValue) => {
        const url = '/apiArticulos?vcodEmpresa=' + cook_idEmp + '&numRepor=' + inputValue;
        const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
        const response = await fetch(url, opPe);
        const data = await response.json();
        return data;
    }, [])

    /** Primer reporte */

    
    const columns = useMemo(() => [
        { header: 'PERIODO', accessorKey: 'PERIODO' },
        { header: 'TRANSID', accessorKey: 'TRANSID' },
        { header: 'DOCNUM', accessorKey: 'DOCNUM' },
        { header: 'OBJTYPE', accessorKey: 'OBJTYPE' },
        { header: 'DOCENTRY', accessorKey: 'DOCENTRY' },
        { header: 'MONEDA', accessorKey: 'MONEDA' },
        { header: 'FECHACONT', accessorKey: 'FECHACONT' },
        { header: 'FECHAEMIC', accessorKey: 'FECHAEMIC' },
        { header: 'FECHAVENC', accessorKey: 'FECHAVENC' },
        { header: 'TIPODOC', accessorKey: 'TIPODOC' },
        { header: 'DETALLETIPODOC', accessorKey: 'DETALLETIPODOC' },
        { header: 'SERIEDOC', accessorKey: 'SERIEDOC' },
        { header: 'NUMERODOC', accessorKey: 'NUMERODOC' },
        { header: 'TIPODOCIDENTIDAD', accessorKey: 'TIPODOCIDENTIDAD' },
        { header: 'RUCSN', accessorKey: 'RUCSN' },
        { header: 'RAZONSOCIALSN', accessorKey: 'RAZONSOCIALSN' },
        { header: 'TOTALFACTEXPO', accessorKey: 'TOTALFACTEXPO' },
        { header: 'BASEIMPONIBLE', accessorKey: 'BASEIMPONIBLE' },
        { header: 'EXONERADA', accessorKey: 'EXONERADA' },
        { header: 'INAFECTA', accessorKey: 'INAFECTA' },
        { header: 'ISC', accessorKey: 'ISC' },
        { header: 'IGV', accessorKey: 'IGV' },
        { header: 'ICBPER', accessorKey: 'ICBPER' },
        { header: 'OTROS', accessorKey: 'OTROS' },
        { header: 'TOTALDOC', accessorKey: 'TOTALDOC' },
        { header: 'TIPOCAMBIO', accessorKey: 'TIPOCAMBIO' },
        { header: 'FECHADOCORIGEN', accessorKey: 'FECHADOCORIGEN' },
        { header: 'TIPODOCORIGEN', accessorKey: 'TIPODOCORIGEN' },
        { header: 'SERIEDOCORIGEN', accessorKey: 'SERIEDOCORIGEN' },
        { header: 'NUMDOCORIGEN', accessorKey: 'NUMDOCORIGEN' },
        { header: 'ANULADO', accessorKey: 'ANULADO' },
        { header: 'PAGADO', accessorKey: 'PAGADO' },
        { header: 'DOCBASE', accessorKey: 'DOCBASE' },
    ], []);

    const DownloadButton = () => {
        return (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              style={{
                backgroundColor: "#ff3e41",
                color: "white",
                border: "none",
                borderRadius: "8px",
                padding: "15px 30px",
                fontSize: "16px",
                cursor: "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#e63a3d")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff3e41")}
              onClick={ async () => {
                onClickListar()
              }}
            >
              Descargar TXT
            </button>
          </div>
        );
      };
      
    const onClickListar = async () => {
        notify_valido("Generando archivo")
        let tipoReporte = 1;
        try {
            Swal.fire({
                title: 'Procesando...',
                html: 'Por favor esperar un momento, gracias.',
                didOpen: () => { Swal.showLoading() },
                customClass: { container: 'my-swal' },
                allowOutsideClick: false
              })

            setdataReport([])
            // setPending(true)
            const newPeriodo= moment(text_fechaD).format('YYYY-MM')
            // .format('YYYYMMDD HH:mm')
            const url_reporte = `/apiReportContabilidad?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}&periodo=${newPeriodo}`;
            console.log(url_reporte);

            // return;
            const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await fetch(url_reporte, opPe);
            if (response.status != 200) {
                // Si el código de estado es 404 (no se encontraron clientes)
                notify_warning('Ocurrio un error en el proceso')
                return;
            }

            // Leer el contenido como un blob
            const blob = await response.blob();
            const contentDisposition = response.headers.get('Content-Disposition');
            const nombreArchivo = contentDisposition
              ? contentDisposition.split('filename=')[1].replace(/"/g, '') // Extraer el nombre
              : 'archivo.txt'; // Valor por defecto si no se encuentra el encabezado
        
            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =nombreArchivo; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // Liberar memoria
            notify_valido("Verificar Archivo en descargas")
        } catch (error) {
            console.log("Ocurrio un error:: ", error);
            notify_error('Ocurrio un error')

        } finally {
            Swal.close()
        }
    };
    
    const generaTxt = async ()=>{

    }

    const soloData = async () => {
        try {
            setPending(true)
            setdataReport([])
            console.log("entor aeidmwoend downd ewo");
            
            let tipoReporte = 1;
            const newPeriodo= moment(text_fechaD).format('YYYY-MM')
            const url_reporte = `/apiReportContabilidad?vcodEmpresa=${cook_idEmp}&numRepor=${tipoReporte}&periodo=${newPeriodo}&EvioData=true`;
            // console.log(url_reporte);

            // return;
            const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await fetch(url_reporte, opPe);
            if (response.status != 200) {
                // Si el código de estado es 404 (no se encontraron clientes)
                notify_warning('Ocurrio un error en el proceso')
                return;
            }
            const data = await response.json();
            console.log(data[0]);
            
            setdataReport(data)

        } catch (error) {
            console.log("Eroor->:",error);
            
        }finally{
            setPending(false)
        }

    }

    const onClickLimpiar = async () => {
        setdataReport([])
        setPending(false)
        setText_fechaD(new Date())
        setText_fechaH(new Date())
        notify_valido("Se limpiaron los campos")
    }

    const onClickExcel = async () => {
        const fileType = "xlsx"
        const nameFileType = "DOCUMENTOS PENDIENTES POR DESPACHO_" + moment(new Date()).format('YYYYMMDD HH:mm')
        const showData = async () => {
            if (dataReport.length !== 0) {
                const ws = XLSX.utils.json_to_sheet(dataReport);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });//array
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, `${nameFileType}.xlsx`);
            } else {
                notify_warning("No existen datos, verificar campos")
            }
        }
        showData();
    }

        return (
            <>
            <div className="row">
                <div className="col-lg-8">
                    <div className="col-lg-4" style={{ zIndex: '3' }}>
                        <label className="form-label mb-0 " >Periodo:</label>
                        <DatePicker
                            locale="es"
                            wrapperClassName='input_date'
                            className="form-control form-control-sm text-center"
                            selected={text_fechaD}
                            onChange={date => setText_fechaD(date)}
                            showMonthYearPicker
                            dateFormat="yyyy-MM" // solo año y mes

                        />
                    </div>

                </div>
                <div className="col-lg-3 text-end">

                    {/* <Tooltip title="Consultar información" placement="top">
                        <>
                            <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            </button>

                        </>
                    </Tooltip> */}
                    
                    {/* <AButton tp_tilde="Generar  TXT" tp_posit="right" bt_class="btn btn-success btn-sm" bt_funct={onClickListar} ic_class="bx bxs-file-txt bx-ms" vl_tpbtn={2} /> */}

                        {/* <Tooltip title="Generar  TXT" placement="top">
                            <>
                                <button className="btn btn-sm btn-label-youtube" onClick={onClickListar} >
                                    <i className='bx  bxs-file-txt bx-ms' ></i>
                                    <span className="spinner-border d-none" role="status" aria-hidden="true"></span>
                                </button>

                            </>
                        </Tooltip> */}
                    {/* <Tooltip title="Exportar" placement="top">
                        <div className="btn-group" role="group">
                            <button id="btn_procesar_excel" type="button" className="btn btn-sm  dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className='bx bxs-file-txt' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                                <button className="dropdown-item bx-ms" onClick={onClickExcel}> TXT  </button>
                            </ul>
                        </div>
                    </Tooltip> */}
                    <AButton tp_tilde="Limpiar y consultar de nuevo" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
                </div>
            </div>
{/* 
            <div className="card-datatable table-responsive ">
                <MaterialReactTable
                    columns={columns}
                    data={dataReport}
                    state={{ isLoading: pending }}
                    initialState={{
                        density: 'compact', showColumnFilters: false,
                        showGlobalFilter: false,
                        columnVisibility: { clave: false, vlSema: false, },
                    }}
                    enableGrouping
                    enableStickyHeader enableColumnResizing
                    muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                    localization={MRT_Localization_ES}
                    enablePagination={true}
                    muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
                    enableGlobalFilterModes
                    rowVirtualizerProps={{ overscan: 150 }}
                    enableRowVirtualization
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Options', //change header text
                            size: 120, //make actions column wider
                        },
                    }}
                    muiCircularProgressProps={{ color: 'error' }}
                    muiSkeletonProps={{
                        animation: 'pulse',
                        height: 28,
                    }}

                />
            </div> */}
            <DownloadButton/>
        </>
    )
}
const ReportContabilidad = () => {
    const [slTipoReport, setSlTipoReport] = useState('');

    const changeSlTipoReport = (event) => {
        setSlTipoReport(event.target.value);
    }

    return (
        <div>
            <h6 className="text-muted"> Reporteria Contabilidad</h6>
            <div className="card card-action">
                <div className="card-body ">
                    <div className="row mb-2">
                        <div className="col-lg-10">
                            <div className="row ">
                                <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                                <div className="col-lg-10">
                                    <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                                        <option value={"9999"} >--Seleccione--</option>
                                        <option value={"1"} > RCONT001 : DOCUMENTOS PENDIENTES POR DESPACHO </option>
                                        <option value={"2"} > RCONT002 : LIBRO ELECTRONICO DE  VENTAS </option>
                                        {/* <option value={"3"} > RAUD003 : En Desarrrollo  </option> */}
                                        {/* <option value={"4"} > RAUD004 : En Desarrrollo  </option> */}
                                        {/* <option value={"5"} > RAUD005 : En Desarrrollo  </option> */}


                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (slTipoReport === '1') && (
                            <Report1
                                dataTipo={slTipoReport}
                            />
                        )
                    }
                    {
                          (slTipoReport === '2' ) && (
                            <Report2 
                              dataTipo={slTipoReport}
                            />
                          )
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ReportContabilidad