import React, { useState, useMemo, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
//selectTablaMultiple , notify_valido
import { notify_warning, notify_error_fijo, notify_valido, newDateFValor,newDateF } from '../../components/utils';
import $ from 'jquery'
import 'react-data-table-component-extensions/dist/index.css';
import '../../App.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { AButton } from '../../components/Button';
import { Box, Modal, Tooltip } from '@mui/material';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2';


const moment = require('moment')

//import Swal from 'sweetalert2';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const CustomSelectCell = ({ row, cook_idEmp, updateData, sucursal, lstBancos, disabled }) => {
  console.log("ingresa CustomSelectCell");
  const rowIndex = row.index;
  const selectedValue = row.getValue('INCuentaEfectivo');
  const handleChange = async(option) => {
    //console.log("option.target.value", option.target.value);
    updateData(rowIndex, option ? option.target.value : null);
    //const codCon = row.original.Codigo
    //const codLiq = option.target.value
    //await updateLiquidador(cook_idEmp,sucursal)
  };

  /**const updateLiquidador = async(cook_idEmp,sucursal) => {
    const url = `/apiListarBancos?vcodEmpresa=${cook_idEmp}&vcodConsolidado=${sucursal}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url, opPe);
    const data = await response.json();
    if (data === 'Success') {
      notify_valido("Se Asigno Responsable") 
    }
    console.log(data);
  }**/
  
  
  return (
    <select className="form-select form-select-sm" value={selectedValue} onChange={handleChange} disabled={disabled} > 
      <option value="9999">--Seleccione--</option>
      {
        lstBancos?.map((item, index) => {
          return <option value={item?.Code} key={index} >{item?.Name}</option>
        })
      }
    </select>
  )

}
const DatePickerCell = ({ value, onChange, disabled }) => {
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : null);

  const handleChange = (date) => {
    setSelectedDate(date);
    console.log("date que cambia", date);
    onChange(date);
  };

  return (
    <div style={{ zIndex: '100000000' }} >
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        dateFormat="yyyy-MM-dd"
        disabled={disabled}
        placeholderText="Select a date"
        locale="es"
        className="form-control form-control-sm text-center"
      />
    </div>
  );
};
const LIQPlanCobranzas = () => {
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  /**   variables de carga de tabla  */
  const [pending, setPending] = useState(false); // variable de la tabla principal 

  const [dataTablaConsol, setdataTablaConsol] = useState([]);
  const [sorting, setSorting] = useState([]);
  /** Cabecera */
  const [emp_sl_t_suc, emp_setSl_t_suc] = useState(''); // sucursal 
  const [inp_text_fecha, setInp_text_fecha] = useState(new Date());
  const [disabledbPa, setdisabledbPa] = useState(true);
  const [text_fechPa, setText_fechPa] = useState(new Date());

  /**Capturamos el codigo de la sede */
  const emp_changeSl_t_suc = (event) => {
    emp_setSl_t_suc(event.target.value);
  }

  /** Listar datos de principales*/
  const onClickListarLiqui = async () => {
    document.getElementById('btn_procesar').disabled = "true";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border';
    setPending(true)
    if (!emp_sl_t_suc || emp_sl_t_suc === "9999") {
      notify_warning(" Debes seleccionar una sucursal")
      setPending(false)

    } else {

      const url1 = '/apilistPLiquidar'
      const Body = { vcodEmpresa: cook_idEmp, vcodSucursal: emp_sl_t_suc, formattedDate: formatDate(inp_text_fecha) };
      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }

      const response = await fetch(url1, opPe);
      const resdatos = await response.json();
      if (resdatos.vl_rsparr === 0) {
        notify_warning("¡No existen datos.!")
        setPending(false)
        setdataTablaConsol([])
        // setdataPreConsol([])
      } else {
        // const tableData = { columns, data: resdatos };
        setdataTablaConsol(resdatos)
        await listBancos()
        // setdataPreConsol(tableData)
        setPending(false)
      }
      console.log("Data de prueba :", dataTablaConsol);

      $(".tabla-resumen .rdt_Table").addClass("table");

    }
    document.getElementById('btn_procesar').disabled = "";
    document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
    document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
  };


  const formatDate = (date) => {
    // Formatear la fecha a yyyy-MM-dd
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };
  /** Columnas de la tabla principal */
  const columPlanillaCobranzas = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Codigo' },
    { 
      header: 'Estado', 
      //accessorKey: 'EstadoCon',
      Cell: ({ row }) => (
        (row.original.LinMotivo != '0') ?<b className="bg-label-info rounded">Por liquidar { row.original.LinMotivo }  Doc</b> :
        (row.original.EstadoCon === "Pendiente") 
              ? <b className="bg-label-primary rounded">Pendiente</b> 
              : <b className="bg-label-secondary rounded">Liquidado</b> 
      )
     },
    { header: 'Fecha', accessorKey: 'Fecha' },
    { header: 'TipoC.', accessorKey: 'TipoDesc' },
    { 
      header: 'EstadoC.', 
      //accessorKey: 'Abierto',
      Cell: ({ row }) => (
           (row.original.Estado === "A") ? 'ABIERTO' : 'LIQUIDADO'
      )
    },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Vehiculo ', accessorKey: 'Vehiculo' },
    { header: 'Chofer', accessorKey: 'Chofer' },
    //{ header: 'CapacidadPeso', accessorKey: 'CapacidadPeso' },
    { header: 'Pedidos', accessorKey: 'Pedidos' },
    { header: 'Clientes', accessorKey: 'Clientes' },
    { header: 'TotalContado', accessorKey: 'TotalContado' },
    { header: 'TotalCredito', accessorKey: 'TotalCredito' },

  ], []);


  /** ================================================================= :> Modal Liquidar <: ================================================================= */
  const [dataPD, setdataDP] = useState([]);
  const [valCodPD, setValCpdDP] = useState();
  const [txtTotales, setTxtTotales] = useState({r1:0, r2:0, r3:0, r4:0});
  const [openModalDP, setopenModalDP] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [pendingDetallePed, setPendingDetallePed] = useState(false);
  const handleOpenPD = (row) => { 
    setopenModalDP(true); 
    //onClickModalPD(row.Codigo)
  };
  const handleClosePD = () => { 
    setopenModalDP(false); 
    handleLimpiar()
  };
  const handleLimpiar = () => {
    setTxtTotales([])
    setdataDP([])
    setRowSelection([])
    setValCpdDP('')
    setBtnDisabled(false)
  }

  const onClickModalPD = async(Code)=> {
    Swal.fire({
      title: 'Cargando datos...',
      html: 'Por favor espere',
      allowOutsideClick: false,
      customClass: { container: 'my-swal' },
      didOpen: () => {
        Swal.showLoading()
      }
    });

    try {
    setValCpdDP('')
    setPendingDetallePed(true)
    const url_lstClientes = `/apiListarItemPlanificar?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}&vCodigo=${Code}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    Swal.close();
    if (data.mensaje === '0') {
      notify_warning('No hay registros asignados.')
      setBtnDisabled(false)
    }else{ 
      setValCpdDP(Code)
      // console.log("Detalle de la data ::", data);
      
      setdataDP(data) 
      setopenModalDP(true)
    }
  } catch (error) {
    Swal.close();
    notify_error_fijo('Error al cargar los datos')
  } finally {
    setPendingDetallePed(false)
  }
  }

  // Nuevo cambio despues de salia a produ, nuevo excel en este  modal de pedidos

  const onClickExcel = async () => {
    console.log("funcionando ");
    
    const fileType = "xlsx"
    const nameFileType = "REPORTE_PEDIDOS_CONSOLIDADO"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataPD.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataPD);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  const handleActulizarTotalesColumna = async(nameFile, Cant) => {
    console.log("nameFile", nameFile);
    console.log("Cant", Cant);
    
    setTxtTotales(prevState => ({
      ...prevState,
      [nameFile]: Cant,
    }));
  }

  const [lstBancos, setLstBancos] = useState([]);
  const listBancos = async() => {
    const url = `/apiListarBancos?vcodEmpresa=${cook_idEmp}&vcodSucursal=${emp_sl_t_suc}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    const response = await fetch(url, opPe);
    const data = await response.json();
    console.log(data);
    setLstBancos(data)
  }
  
  
  /** Columnas de modal - liquidar */
  const columnsPD = useMemo(() => [
    { header: 'Documento',     accessorKey: 'txtDocumento',  }, //1
    { header: 'FechaEmision',     accessorKey: 'txtFechaEmision',  }, //1
    { header: 'DocEntry',     accessorKey: 'txtDocEntry',  }, //1
    { header: 'DocNum',       accessorKey: 'txtNroDocu', }, //2
    { header: 'Sucursal',     accessorKey: 'txtSucursa', }, //3
    { header: 'CodCliente',   accessorKey: 'txtCodClie', }, //4
    { header: 'NomCliente',   accessorKey: 'txtNomClie', }, //5
    { header: 'SaldoCliente', accessorKey: 'txtSaldoCl', }, //5
    { header: 'Vendedor',     accessorKey: 'txtNomVend', }, //6
    { header: 'Ruta',         accessorKey: 'txtCodRuta', footer: 'Total' }, //7
    { header: 'Condicion',    accessorKey: 'txtCondici', }, //8
    { header: 'Total',        accessorKey: 'txtTotal',  }, //9
    { 
      header: 'Pago',     
      accessorKey: 'txtPago',
      Cell: ({ row }) => (
        <input type="number" className="form-control form-control-sm" value={row.original['txtPago']} onChange={handleFieldTabChange('txtPago', row)} />
      ),
      footer: `Total Pago: ${txtTotales.r1}`,
    },//10
    { 
      header: 'Rechazo',      
      accessorKey: 'txtRechazo', 
      Cell: ({ row }) => (
        <input type="number" className="form-control form-control-sm" value={row.original['txtRechazo']} onChange={handleFieldTabChange('txtRechazo', row)} disabled />
      ),
      footer: `Total Rechazo: ${txtTotales.r2}`,
      
    }, //11
     
    { 
      header: 'TipoPago', 
      accessorKey: 'txtTipoPag',
      Cell: ({ row }) => (
        
        <select className="form-select form-select-sm" value={row.original['txtTipoPag']} onChange={handleFieldTabChange('txtTipoPag', row)}>
          <option value="">--Seleccione--</option>
          <option value="1">Transferencia Bancaria</option>
          <option value="2">Efectivo</option>
        </select>
      ),
      footer: `Total: ${txtTotales.r3}`,      
    }, //13
    { 
      header: 'FechaDeposito', 
      accessorKey: 'txtFechaDate',
      Cell: ({ row }) => (
        
          <DatePickerCell
          //value={row.original['txtFechaDate']}
          value={new Date()}
          onChange={(newValue) => {
            const updatedData = dataPD.map((item) =>
              item.txtDocEntry === row.original.txtDocEntry ? { ...item, txtFechaDate: newValue } : item
            );
            
            setdataDP(updatedData);
          }}
          //disabled={ (row.original.txtTipoPag === '1') ? false : true}
        />
        
      ),
      footer: `Total Liqui: ${txtTotales.r4}`,       
    },
    { header: 'NroOperación.',
      accessorKey: 'txtNumOper',
      Cell: ({ row }) => (
        <input type="text" className="form-control form-control-sm" value={row.original['txtNumOper']} onChange={handleFieldTabChange('txtNumOper', row)} 
        disabled={ (row.original.txtTipoPag === '1') ? false : true}/>
      ),
    }, //13
    {
      header: 'Bancos',
      accessorKey: 'INCuentaEfectivo',
      Cell: ({ row }) => (
        < >
          <CustomSelectCell row={row} cook_idEmp={cook_idEmp} updateData={updateData} sucursal={emp_sl_t_suc} lstBancos={lstBancos}  disabled={ (row.original.txtTipoPag === '1') ? false : true}/>
        </>
      ),
    },
    {
      header: 'Motivo no liquidado',
      accessorKey: 'txtmotivoLiquidado',
      Cell: ({ row }) => (
        
        <select className="form-select form-select-sm" value={row.original['txtmotivoLiquidado']} onChange={handleFieldTabChange('txtmotivoLiquidado', row)}>
          <option value="">--Seleccione--</option>
          <option value="DC">Documento al credito</option>
          <option value="CH">Cheque</option>
          <option value="CD">Contado  diferido</option>
          <option value="FS">Facturacion a servis</option>
          <option value="VE">Documento venta especial</option>
        </select>
      ),
    },
    { header: 'Comentario.',
      accessorKey: 'txtComentario',
      Cell: ({ row }) => (
        <input type="text" className="form-control form-control-sm" value={row.original['txtComentario']} onChange={handleFieldTabChange('txtComentario', row)} 
        />
      ),
    }
  ], [txtTotales, lstBancos]);

  const updateData = (rowIndex, selectedValue) => {
    console.log("selectedValue", selectedValue);
    setdataDP((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex].INCuentaEfectivo = selectedValue;
      return updatedData;
    });
  };

  
  const handleFieldTabChange = (fieldName, row) => (event) => {
    const value = event.target.value;

    if (fieldName === "txtTipoPag" && value === '2') {
      console.log("entra Efectivo ");
      setdataDP((prevEmp_lst) => {
        const nuevosDatos = [...prevEmp_lst];
        const newRow = nuevosDatos[row.index];
        newRow['txtNumOper'] = '';
        return nuevosDatos;
      });
    } 
    setdataDP((prevEmp_lst) => {
      const nuevosDatos = [...prevEmp_lst];
      const newRow = nuevosDatos[row.index];
      newRow[fieldName] = value;
      return nuevosDatos;
    });
  };

  const handleFileDateChange = (fieldName, row, date) => {
  //const handleFileDateChange = (date) => {
    // setdataDP({
    //   ...dataPD,
    //   txtFechaDate: date, // Actualiza el valor de la fecha seleccionada
    // });
    setdataDP((prevEmp_lst) => {
      const nuevosDatos = [...prevEmp_lst];
      const newRow = nuevosDatos[row.index];
      newRow[fieldName] = date;
      return nuevosDatos;
    });
  };
  
  useEffect(() => {
    
    

    let totalSum1 = 0;
    let totalSum2 = 0;
    let totalResta = 0;
    let total= 0;
    dataPD.forEach(obj => {
      //console.log("obj.txtPago=====",obj.txtPago);
      total += parseFloat(obj.txtTotal);
      totalSum1 += parseFloat(obj.txtPago);
      totalSum2 += parseFloat(obj.txtRechazo);
    }); 
    //console.log("totalSum2", totalSum2);
    //total = parseFloat(totalSum1) + parseFloat(totalSum2)
    totalResta = Number(parseFloat(total) - parseFloat(totalSum2))
    
    handleActulizarTotalesColumna('r1',totalSum1.toFixed(3))
    handleActulizarTotalesColumna('r2',totalSum2.toFixed(3))
    handleActulizarTotalesColumna('r3',total.toFixed(3))
    handleActulizarTotalesColumna('r4',totalResta.toFixed(3))
    
    
    
  }, [dataPD]);

  const arrDataProcesada = async(validarUActivos) =>{
    const batchSize = 50;
    const promises = []
    for (let i = 0; i < validarUActivos.length; i += batchSize) {
        const batch = validarUActivos.slice(i, i + batchSize);
        let respd = fuEnvioAct(batch)
        promises.push(respd);
    }
    const resultados = await Promise.all(promises);
    return resultados.flat();
  }
  const fuEnvioAct = async(validarUActivos) => {
    /* 
      Funcion de envio
    */
    const url = '/apiInsertarLiquidar'
    const body = {
      vcodEmpresa: cook_idEmp,
      vcodSucursal: emp_sl_t_suc,
      arrDataSelec: validarUActivos,
      valCodPD:valCodPD , 
      text_fechPa: newDateFValor(text_fechPa) 
    };
    const opPe = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, opPe);
    const resdatos = await response.json();
    return resdatos;
  }
  const onClickProcesar = async(table, row) => {
    /**
     *  Funcion de procesar
     * 
     */

    setBtnDisabled(true)
    setPendingDetallePed(true)
    let valInsert = 0
    const selectedRFile = [];
    let selectedRows = table.getSelectedRowModel().rows;
    console.log("CAntidad de  data de la tbal ::",selectedRows);
    // return;
    
    if (selectedRows.length ) {

      const arrMotivoLique = selectedRows.filter(
        objeto => objeto.original.txtmotivoLiquidado != '' && 
                  objeto.original.txtmotivoLiquidado != null
      );
      const arrSinMotivo = selectedRows.filter(
        objeto => objeto.original.txtmotivoLiquidado == ''
      );
      // console.log( "arrMotivoLique:", arrMotivoLique);
      

      let  mesjaeAlerta = '';
     
      // <span style="color: red;">${fecha}</span>
      // console.log( "mesjaeAlerta:: ", mesjaeAlerta);
      if (arrMotivoLique.length!=0 && arrSinMotivo.length!=0  ){ 
        mesjaeAlerta = `
        Se han encontrado ${arrSinMotivo.length} DOC para pagar en la fecha: ${newDateFValor(text_fechPa)}.<br>
        Se han encontrado ${arrMotivoLique.length} DOC con 
        <span style="color: red;">motivo de no liquidación</span>.<br>
        ¿Desea continuar?
        `;
      }
      if (arrMotivoLique.length==0 && arrSinMotivo.length!=0  ){ 
        mesjaeAlerta = `
        Se han encontrado ${arrSinMotivo.length} DOC para pagar en la fecha: ${newDateFValor(text_fechPa)}.<br>`
      }
      if (arrMotivoLique.length!=0 && arrSinMotivo.length==0  ){ 
       mesjaeAlerta =  ` Se han encontrado ${arrMotivoLique.length} DOC con 
        <span style="color: red;">motivo de no liquidación</span>.<br>
        ¿Desea continuar?
        `;
      }

      Swal.fire({
        title: 'ADVERTENCIA',
        html: mesjaeAlerta , //  '¿Desea pagar para '+newDateFValor(text_fechPa)+ '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        customClass: { container: 'my-swal' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          /** validacion de los codigos de los clientes */
          // solo codigo de cliente
          const soloCodCliente = selectedRows.map(objeto => objeto.original.txtCodClie);
          // console.log("selectedRows::", soloOriginal);
          if ((await validarClientesActivos(soloCodCliente)===false)) {
            handleLimpiar()
            onClickListarLiqui()
            handleClosePD()
            setPendingDetallePed(false)
            return;
          }
          
          selectedRows.map((key0) => {
            const vlD1 = key0.original.txtDocEntry
            const vlD2 = key0.original.txtTipoPag
            const vlD3 = key0.original.txtNumOper
            //key0.original;
            //console.log("original",key0.original);
            //console.log("txtTipoPag => " + vlD2 +  " :: txtNumOper => ",vlD3);
            if (vlD1 && vlD2==='1' && vlD3 ==="") {
              valInsert = 1;
              //console.log("Cod => "+ vlD1 + ' esta con tipo de pago TRANFERENCIA, agregar numero de operacion');
              notify_error_fijo('Codigo '+ vlD1 + ' esta con tipo de pago TRANFERENCIA, agregar numero de operacion')
            }
            selectedRFile.push(key0.original)
          })
          if (valInsert !== 1) {

            console.log("LIQUIDACIONES => ingresa a insertar ");
            console.log("selectedRFile => ", selectedRFile);

            /** Datos mayores que 0 en rechazo */
            const arrDatosRechazoIgu = selectedRFile.filter((item) => Number(item.txtRechazo)===0 )
            console.log("arrDatosRechazoIgu => ", arrDatosRechazoIgu);

            /** Datos mayores que 0 en rechazo */
            const arrDatosRechazoMay = selectedRFile.filter((item) => Number(item.txtRechazo)>0 )
            console.log("arrDatosRechazoMay => ", arrDatosRechazoMay);

            const arrDatosQuedanNC = [];
            /** Analizamos segun lo requerido */
            arrDatosRechazoMay?.map((key) => {
              console.log("ingresa al map");
              
              const vPago   = Number(key.txtPago);
              
               if (Number(key.txtTotal) === Number(vPago)) {
                console.log("ingresa a txtTotal > rspSuma");
                 arrDatosQuedanNC.push(key)
               }else{
                arrDatosRechazoIgu.push(key)
               }
            })
            
           

            Swal.fire({
              title: 'Procesando...',
              html: 'Por favor esperar un momento, gracias.',
              didOpen: () => { Swal.showLoading() },
              customClass: { container: 'my-swal' },
              allowOutsideClick: false
            })
            const resdatos = await arrDataProcesada(arrDatosRechazoIgu);
            console.log(
              "apiInsertarLiquidar => resDatos ========> ",
              resdatos);
            
            setTimeout(() => {
              Swal.close()
              if (arrDatosQuedanNC.length>1) {
                arrDatosQuedanNC.map((key1) => {
                  notify_warning("Al documento "+ key1.txtNroDocu + " le falta Nota de Credito.")
                })  
              }else{
                notify_valido("Se liquido correctamente, esperar unos segundos y validar nuevamente.")
              }
              handleLimpiar()
              onClickListarLiqui()
              handleClosePD()
              setPendingDetallePed(false)
            }, 5000);
            
          }else{
            console.log("no ingresa hay datos no asignados correctamente");
          }
        }
      })
    }else{
      notify_warning('Seleccione las filas que quiere actualizar  ')
    }
    setPendingDetallePed(false)
    setBtnDisabled(false)

  };
  const conClickCerrarConsolidado = async(row) => {
    Swal.fire({
      title: 'ADVERTENCIA',
      text: '¿Desea cerrar consolidado '+row.Codigo+'?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      customClass: { container: 'my-swal' }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url1 = '/cerrarConsolidadoLiq'
        const Body = { vcodEmpresa:cook_idEmp, vcodigo: row.Codigo };
        const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(Body) }
        const response = await fetch(url1, opPe);
        const resdatos = await response.json();
        console.log("resdatos", resdatos);
        if (resdatos.vl_res === 1) {
          notify_valido("¡Se proceso correctamente.!")
          onClickListarLiqui()
        } else { console.log("error "); }
        Swal.close()
      }
    })
  }

  const validarClientesActivos = async (dataCode) => {
    //
    try {
      const body = {
        vcodEmpresa: cook_idEmp + "",
        codesClien: dataCode
      }
      const config = {
        method: 'POST', // Especificamos que es una solicitud POST
        headers: {
          'Content-Type': 'application/json' // Indica que el cuerpo es JSON
        },
        body: JSON.stringify(body)
      }
      const responseArticulos = await fetch('/apiClientesActivos', config)
      const respu = await responseArticulos.json();
      console.log(respu);
      
      if (Array.isArray(respu)){
        respu.forEach( element =>{
          if(element['estado'] == 'N'){
            const Cadena = 'Cliente no activo: '+ element['CardCode']
            notify_error_fijo(Cadena)
          }
        })
        const tieneEstadoF = respu.some(objeto => objeto['estado']  === 'N');
        if(tieneEstadoF){
          return false
        }
      }else{
        return true
      }
    } catch (error) {
      console.log("ocurrio un error:: validarClientesActivos");
    }

  }

  return (
    <div>
      <h6 className="text-muted">Liquidación - Planilla de Cobranzas</h6>
      <div className="card card-action">
        {/* <div className="card-header ">
                <div className="card-action-title">Lista </div>
            </div> */}
        <div className="card-body ">
          <div className="row">
            <div className="col-lg-2">Sucursal:</div>
            <div className="col-lg-2 ">
              <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
            </div>
            <div className="col-lg-1">Fecha:</div>
            <div className="col-lg-2" style={{ zIndex: 1000 }}>
              <DatePicker
                id="fecha"
                locale="es"
                className="form-control form-control-sm text-center"
                selected={inp_text_fecha}
                onChange={date => setInp_text_fecha(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <div className="col-lg-4 text-end ">
              <Tooltip title="Consultar información" placement="top">
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListarLiqui} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                  &nbsp;Consultar
                </button>
              </Tooltip>&nbsp;
            </div>
          </div>
          <div className="card-datatable table-responsive">
            {/* Tabla Nueva  */}


            <MaterialReactTable
              columns={columPlanillaCobranzas}
              data={dataTablaConsol}
              onSortingChange={setSorting}
              state={{ isLoading: pending }}
              initialState={{
                density: 'compact',
                expanded: true,
              }}
              enableGrouping
              enableStickyHeader
              enablePagination={true}
              enableBottomToolbar={true}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px', p: '0rem' }}>
                  {/* Botón al final */}
                  <AButton 
                    tp_tilde="Liquidar" bt_descr='Liquidar' tp_posit="top" bt_class="btn btn-sm btn-label-info " 
                    bt_funct={() => onClickModalPD(row.original.Codigo)} ic_class="bx bx-ms bxs-user-detail" vl_tpbtn={1} 
                    disabled={(row.original.Estado === "A") ? false : true } />
                  
                  <AButton 
                    tp_tilde="Cerrar consolidado" 
                    tp_posit="top" 
                    bt_class="btn btn-sm btn-icon btn-label-warning" 
                    disabled={(row.original.EstadoCon === 'Pendiente') ? false : true} 
                    bt_funct={() => conClickCerrarConsolidado(row.original)}
                    ic_class="bx bx-ms bxs-lock" vl_tpbtn={1} />
                    {/* Otros botones o contenido según sea necesario */}
                </Box>
              )}
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}

            />
          </div>
        </div>
      </div>

      {/************  Modal Detalle Pedidos Asignados  ******************/}
      <Modal
        open={openModalDP}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '75%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={handleClosePD}
          >
            <i className='fa fa-x'></i>
          </button>
          <div className="py-4" >
            <div className="row">
              <div className="col-lg-3">
                <p>Liquidar - {valCodPD}</p>
              </div>
              <div className="col-lg-2">Fecha Pago: </div>
              <div className="col-lg-4">
                
                <DatePicker
                  locale="es"
                  className="form-control form-control-sm text-center "
                  selected={text_fechPa}
                  onChange={date => setText_fechPa(date)}
                  dateFormat="yyyy-MM-dd"
                  // disabled={disabledbPa}
                />
              </div>


            </div>
            

            
            <MaterialReactTable
            
              columns={columnsPD}
              data={dataPD}
              state={{ rowSelection, isLoading: pendingDetallePed }}
              initialState={{  density: 'compact', showColumnFilters: false, showGlobalFilter: false }}
              enableGrouping 
              enableStickyHeader
              enableStickyFooter 
              enableColumnResizing
              muiSelectCheckboxProps={{ color: 'error' }}
              getRowId={(row) => row.userId}
              onRowSelectionChange={setRowSelection}
              muiToolbarAlertBannerChipProps={{ color: 'error' }}
              localization={MRT_Localization_ES}
              enablePagination={true}
              muiTableBodyCellProps={{color: 'danger', sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              //enableRowVirtualization
               muiTableContainerProps={{ sx: { maxHeight: 400,  zIndex: 1  } }}
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px', }}>
                  <AButton tp_tilde="Procesar"
                    tp_posit="top" bt_class="btn btn-sm btn-info "
                    //bt_descr="Procesar"
                    ic_class="bx bx-ms bx-sync"
                    disabled={btnDisabled}
                    bt_funct={() => onClickProcesar(table, row)}
                    vl_tpbtn={2} 
                  />
                  {/* Segundo boton para listar  */}
                  <AButton tp_tilde="Descargar"
                    tp_posit="top" bt_class="btn btn-sm btn-success "
                    //bt_descr="Descargar"
                    ic_class="bx bx-ms bxs-file-export"
                    // disabled={btnDisabled} onClickExcel
                    bt_funct={onClickExcel}
                    vl_tpbtn={2} 
                  />
                    {/* <DatePicker
                  locale="es"
                  className="form-control form-control-sm text-center "
                  selected={text_fechPa}
                  onChange={date => setText_fechPa(date)}
                  dateFormat="yyyy-MM-dd"
                  // disabled={disabledbPa}
                  
                />   */}
                </Box>
              )}
              enableRowSelection
              muiTableHeadCellProps={{
                color: 'error'
              }}
              muiTopToolbarProps={{
                sx: {
                  '& .Mui-ToolbarDropZone': {
                    border: '1px solid red',
                    zIndex: '2 !important',
                    // color: 'white', //color texto
                    backgroundColor: '#d93519',
                    color: 'white'
                  }
                }
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
                
              }}
              positionToolbarAlertBanner="bottom"
              muiCircularProgressProps={{ color: 'error' }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </div>

        </Box>
      </Modal>

      <ToastContainer />

    </div>
  )
}


export default LIQPlanCobranzas